$(document).ready(function () {
    $(".show-details").on("click", function () {
        const dataId = $(this).attr("dataId");

        const opended = document.querySelectorAll(".opportunityList");

        opended.forEach((element) => {
            const elementId = $(element).attr("dataId");

            if (elementId !== dataId) {
                $(`button[dataId=${elementId}]`).addClass("button-bg-yellow");
                $(`button[dataId=${elementId}]`).removeClass("button-bg-blue");
                $(`button[dataId=${elementId}]`).text("Ver Detalhes");
                $(`div.show-details-opportunity-${elementId}`).hide(1000);
            }
        });

        if ($(`div.show-details-opportunity-${dataId}:visible`).length) {
            $(this).addClass("button-bg-yellow");
            $(this).removeClass("button-bg-blue");
            $(this).text("Ver Detalhes");
            return $(`div.show-details-opportunity-${dataId}`).hide(1000);
        }

        $(this).removeClass("button-bg-yellow");
        $(this).addClass("button-bg-blue");
        $(this).text("Menos detalhes");

        return $(`div.show-details-opportunity-${dataId}`).show("slow");
    });
});

let defaultTransform = 0;
function goNext() {
    defaultTransform = defaultTransform - 398;
    var slider = document.getElementById("slider");
    if (Math.abs(defaultTransform) >= slider.scrollWidth / 1.7)
        defaultTransform = 0;
    slider.style.transform = "translateX(" + defaultTransform + "px)";
}

next.addEventListener("click", goNext);

function goPrev() {
    var slider = document.getElementById("slider");
    if (Math.abs(defaultTransform) === 0) defaultTransform = 0;
    else defaultTransform = defaultTransform + 398;
    slider.style.transform = "translateX(" + defaultTransform + "px)";
}
prev.addEventListener("click", goPrev);
